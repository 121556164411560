<template>
  <base-view id="employer-resources">
    <view-subnav-section title="Employer Resources" routed :links="links" />
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSubnavSection from "@/components/layout/ViewSubnavSection.vue";

export default {
  components: {
    BaseView,
    ViewSubnavSection
  },
  data() {
    return {
      links: [
        "Open Enrollment",
        // "Plan Resources",
        "COBRA Resources",
        "Virtual Benefit Fair",
        "Templates",
        "Materials for Employees"
        // "Submitting Info to RMR",
      ]
    };
  }
};
</script>
